export default {
  props: {
    validByAlert: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isListInvalidByAlert() {
      return (
        this.validations.convertedSettings.listId.$invalid &&
        !this.validations.convertedSettings.listId.validByAlert
      );
    },
  },
  methods: {
    onListChange() {
      this.validByAlert.list = true;
    },
  },
};
