<template lang="pug">
.content
  .vertical-time-line
    .step
      .circle-holder
        .number 1
      .d-flex.container
        .title {{ $t('integrationFlow.integrationModal.general.copyApiKey') }}
        .description
          a(
            href="https://help.activecampaign.com/hc/en-us/articles/207317590-Getting-started-with-the-API#how-to-obtain-your-activecampaign-api-url-and-key"
            target="_blank"
          ) {{ $t('integrationFlow.integrationModal.general.howToGetApiKey') }}
    .step
      .circle-holder
        .number 2
      .d-flex.container
        .title {{ $t('integrationFlow.integrationModal.general.pasteApiKey') }}
        .description
          .input-holder
            om-input#apiUrl.mb-auto.w-40.mr-3(
              v-model.trim="settings.apiUrl"
              type="text"
              :label="$t('integrationFlow.integrationModal.general.pasteApiUrl')"
            )
              template(#error v-if="validations.apiUrl.$error && !validations.apiUrl.required")
                span {{ $t('integrationFlow.integrationModal.general.apiUrlError') }}
            om-input#apiKey.mb-auto.w-40.mr-3(
              v-model.trim="settings.apiKey"
              type="text"
              :label="$t('integrationFlow.integrationModal.general.pasteApiKey')"
            )
              template(#error v-if="validations.apiKey.$error && !validations.apiKey.required")
                span {{ $t('integrationFlow.integrationModal.general.apiKeyError') }}
              template(#error v-if="validations.apiKey.$error && !validations.apiKey.validByAlert")
                span {{ $t('integrationFlow.integrationModal.general.apiKeyInvalid') }}
              template(#success v-if="validByAlert.apiKeyFixed")
                span {{ $t('integrationFlow.integrationModal.general.apiKeyFixed') }}
    .step
      .circle-holder
        .number 3
      .d-flex.container
        .title {{ $t('integrationFlow.integrationModal.general.addName') }}
        .description
          .input-holder
            om-input#name.mb-auto.w-100(
              v-model.trim="settings.name"
              type="text"
              :placeholder="$t('integrationFlow.integrationModal.general.namePlaceholder')"
              :helpText="$t('integrationFlow.integrationModal.general.inputHelp')"
            )
              template(#error v-if="validations.name.$error && !validations.name.required")
                span {{ $t('requiredField') }}
</template>
<script>
  export default {
    props: {
      settings: {
        type: Object,
        required: true,
      },

      validations: {
        type: Object,
        required: true,
      },

      isNew: {
        type: Boolean,
        default: true,
      },

      validByAlert: {
        type: Object,
        required: true,
      },
    },
  };
</script>
